.main_text {
  color: black;
  font-size: 20px;
  font-weight: bold;
}

.sub_title {
  font-size: 22px;
  font-weight: bold;
}

.option {
  height: 50px;
  flex: 1;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  padding: 8px;
}

.person_num_input {
  height: 50px;
  font-size: 15px;
  flex: 1;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  padding: 8px;
  color: rgb(0, 111, 185);
}
textarea {
  color: #000;
  font-size: 13px;
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  height: 150px;
  width: 100%;
  padding-left: 10px;
}
.li {
  list-style-type: disc;
  font-size: 14px;
}

.main_container {
  height: 100%;
  width: 100%;
  background-color: #f3f3f3;
  display: flex;

  .contact-us {
    padding: 3em 0 1em;
    display: flex;
    width: 100%;
    flex-direction: column;

    .contact-us-form {
      width: 100%;

      .form {
        flex: 1;
        background-color: #fff;
        border-radius: 0;
        padding: 40px;

        .contact-header {
          margin-bottom: 20px;
          font-family: 'Segoe UI', sans-serif;
          font-size: 25px;
        }

        .border-line {
          height: 1px;
          margin: 2em 0;
        }

        .label {
          text-align: flex-start;
        }

        .form_input {
          height: 50px;
          flex: 1;
          border-radius: 5px;
          border: 1px solid #d9d9d9;
          padding: 8px;

          .option {
            width: 100%;
          }

          &.message {
            height: auto;
          }
        }

        .custom_btn {
          height: 100px;
          width: 165px;
          background-color: rgb(0, 111, 185) !important;
          border-radius: 5px;
          justify-content: center;
          align-items: center;
          flex-direction: row;
          align-self: center;
          color: white;
          width: 100%;
          height: 50px;
          font-family: 'Segoe UI', sans-serif;
          font-size: 14px;
        }

        .custom_btn_bottom {
          height: 100px;
          width: 165px;
          background-color: rgb(0, 111, 185);
          border-radius: 5px;
          justify-content: center;
          align-items: center;
          flex-direction: row;
        }

        .custom_btn_text {
          color: white;
          font-size: 13px;
          font-weight: bold;
        }
      }
    }

    .direction-cont {
      .header {
        width: 100%;
        padding-top: 30px;
        margin-top: 10px;
        margin-bottom: 10px;
      }

      .map {
        width: 100%;
        padding-top: 10px;
        margin-top: 0;
        margin-bottom: 20px;

        iframe {
          width: 100%;
        }
      }

      .contact-details {
        width: 100%;
        margin-top: 20px;
        padding-top: 10px;
        display: flex;

        .cont {
          display: flex;
          width: 100%;
          flex-direction: column;
          gap: 15px;

          .label {
            color: black;
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 5px;
            color: #434343;
          }
        }
      }
    }
  }

  @media (max-width: 830px) {
    .contact-us {
      flex-direction: row;

      .contact-us-form {
        .form {
          padding: 15px;

          .contact-header {
            font-size: 20px;
            margin-bottom: 10px;
          }

          .border-line {
            margin: 1em 0 2em;
          }

          .form_input {
            .option {
              width: 40%;
            }
          }

          .label {
            text-align: center;
          }

          .custom_btn {
            width: 100%;
            padding-left: 10%;
            padding-right: 10%;
          }
        }
      }
    }
  }
}
