.sub-text{
    font-size: 17px;
    color: #707070;
    font-weight: bolder;
}
.header-text{
    font-size: 20px;
    color: #707070;
    font-weight: bolder;
}
.big-item-sub{
    color: white;
    font-size: 14px;
}
.big-item-main{
    color: white;
    font-size: 28px;
    font-weight: bolder;
}
.big-item-header{
    padding: 20px 30px 0 30px;
    width: 356px;
    z-index: 1;
    border-radius: 10px;
}
.big-item-container{
    height: 365px;
    width: 356px;
    border-radius: 10px;
}