.history-item-container {
  gap: 20px;

  .history-item-list {
    display: flex;
    align-self: stretch;
    border-radius: 10px;
    background-color: white;
    flex-direction: row;
    margin-bottom: 20px;
    cursor: pointer;
    position: relative;
    z-index: 10;
    width: 60%;
    margin: 0 auto 20px;

    .btn.btn-primary.rate-now-button {
      background-color: rgb(0, 111, 185) !important;
      font-size: 14px;
      position: relative;
      z-index: 999;
      border: none;
      box-shadow: none;
    }
  }
}
@media (max-width: '425.98px') {
  .history-item-container {
    

    .history-item-list {
      display: flex;
      align-self: stretch;
      border-radius: 10px;
      background-color: white;
      flex-direction: column;
      margin-bottom: 20px;
      cursor: pointer;
      position: relative;
      z-index: 10;
      width: 60%;
      margin: 0 auto 20px;

    }
  }
}
