.hotel-cont {
  display: inline-flex;
  flex-direction: column;
  flex: 1;

  .hotel-item {
    align-self: stretch;
    border-radius: 10px;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    cursor: pointer;
  }

  .hotel-image {
    height: 100%;
    width: 150px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    min-width: 150px;
    object-fit: cover;
  }

  .hotel-details {
    flex: 1;
    display: inline-flex;
    flex-direction: column;

    .first-row {
      display: inline-flex;
      justify-content: space-between;
      align-self: stretch;
      padding: 15px;
      flex-direction: row;

      h3.primary-text {
        font-weight: bold;
        font-size: 22px;
        color: #006fb9;
        align-self: flex-start;
      }

      .icons {
        display: inline-flex;
        flex-direction: row;
        align-items: center;

        .star {
          margin-right: 5px;
        }

        .rating {
          font-size: 14px;
          color: #fcc203;
          font-weight: bold;
          margin: 0 15px 0 0;
        }

        .best-value {
          display: inline-flex;
          flex-direction: row;

          canvas {
            margin-right: 5px;
          }

          .verified-text {
            font-size: 14px;
            color: #006fb9;
            font-weight: bold;
            margin: 0;
          }
        }
      }
    }

    .line {
      height: 1px;
      width: 100%;
      background-color: #e8e8e8;
      display: inline-flex;
    }

    .second-row {
      display: inline-flex;
      flex: 1;
      padding: 15px;
      flex-direction: column;

      .first-line {
        display: inline-flex;
        flex-direction: row;
        margin-bottom: 10px;

        .location {
          display: inline-flex;
          flex-direction: row;
          align-items: center;
          flex: 1;

          canvas {
            margin-right: 5px;
          }

          .hotel-location {
            font-size: 14px;
            color: #646464;
            font-weight: bold;
            margin: 0;
          }
        }

        .calendar {
          display: inline-flex;
          flex-direction: row;
          align-items: center;
          flex: 1;

          canvas {
            margin-right: 5px;
          }

          .hotel-date {
            font-size: 14px;
            color: #646464;
            font-weight: bold;
            margin: 0;
          }
        }
      }

      .second-line {
        display: inline-flex;
        align-items: center;
        flex: 1;

        canvas {
          margin-right: 5px;
        }

        .hotel-address {
          font-size: 14px;
          color: #646464;
          font-weight: bold;
          margin: 0;
        }
      }
    }

    .third-row {
      display: inline-flex;
      flex-direction: row;
      background-color: #43980f;
      border-bottom-right-radius: 10px;
      align-content: center;
      align-self: stretch;
      padding: 10px;

      .price {
        display: inline-flex;
        flex-direction: row;
        flex: 1;
        align-items: center;
        padding-left: 10px;
        padding-right: 10px;

        p.text-header {
          font-size: 16px;
          color: #fff;
          font-weight: bold;
          margin: 0 10px 0 0;
        }
      }

      .tax-inclusive {
        color: #fff;
        display: block;
        margin: 0;
        font-size: 12px;
      }

      .pax-left {
        color: #fff;
        font-size: 16px;
        font-weight: bold;
        margin: 0;
      }
    }
  }

  @media (max-width: 767px) {
    .hotel-item {
      flex-direction: column;

      .hotel-image {
        height: 200px;
        width: 100%;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 0;
        border-top-right-radius: 10px;
      }

      .hotel-details {
        .first-row {
          flex-direction: column;
        }

        .second-row {
          .first-line {
            flex-direction: row;
            justify-content: space-between;
          }

          canvas {
            margin-right: 5px;
          }
        }

        .third-row {
          flex-direction: row;
          justify-content: space-between;
          border-bottom-left-radius: 10px;

          .price {
            flex-direction: column;
            flex: unset;
          }
        }
      }
    }
  }
}
