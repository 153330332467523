.card-insurance {
  padding: 0 2em 3em;

  .insurance-form-title {
    display: flex;
    align-items: center;
    height: 100%;

    p {
      padding: 0;
      margin: 0;
      text-align: left;
      font-family: 'Ubuntu', sans-serif;
      font-size: 25px;
      font-weight: 500;
      letter-spacing: 0px;
      color: #006fb9;
      opacity: 1;
      text-transform: uppercase;
    }
  }

  .insurance-form-icons {
    padding: 1em 0 1em 0;
    p {
      text-align: left;
      font-family: 'Ubuntu', sans-serif;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0px;
      color: #434343;
      opacity: 1;
      padding: 0;
      margin: 0.5em 0;
    }

    p.premium-rates,
    p.policy-benefits,
    p.medical-brochure {
      position: relative;
      padding: 0 0 0 1.5em;

      &::before {
        position: absolute;
        content: '';
        background-image: url('../../../shared/assets/icons/check.svg');
        background-size: contain;
        width: 20px;
        height: 20px;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
      }
    }
    p.view-policy {
      position: relative;
      padding: 0 0 0 1.5em;

      &::before {
        position: absolute;
        content: '';
        background-image: url('../../../shared/assets/icons/viewpolicy.png');
        background-size: contain;
        background-repeat: no-repeat;
        width: 20px;
        height: 20px;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
      }
    }
    p.medical-brochure {
      position: relative;
      padding: 0 0 0 1.5em;

      &::before {
        position: absolute;
        content: '';
        background-image: url('../../../shared/assets/icons/covid.png');
        background-size: contain;
        background-repeat: no-repeat;
        width: 20px;
        height: 20px;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
      }
    }
  }

  .horizontal-line {
    margin: 0;
  }

  .insurance-form {
    h4 {
      text-align: left;
      font-family: 'Ubuntu', sans-serif;
      font-size: 20px;
      font-weight: 500;
      letter-spacing: 0px;
      color: #434343;
      opacity: 1;
      padding: 0;
      margin: 0 0 2em;
    }

    label {
      text-align: left;
      font-family: 'Ubuntu', sans-serif;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0px;
      color: #434343;
      opacity: 1;
      padding: 0;
    }

    select,
    input {
      padding: 0.7em;
      cursor: pointer;
    }

    .rows {
      margin: 0 0 2em;
    }

    .buttons {
      margin: 1em 0 0;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 1em;

      .btn.btn-primary.cancel {
        background-color: #e1e1e1 !important;
        font-family: 'Ubuntu', sans-serif;
        color: #fff;
        border: none;
        padding: 0.8em 3.5em;
        font-size: 16px;
        border-radius: 8px !important;
      }

      .btn.btn-primary.submit {
        background-color: #0bc175 !important;
        color: #fff;
        font-family: 'Ubuntu', sans-serif;
        border: none;
        padding: 0.8em 3.5em;
        font-size: 16px;
        border-radius: 8px !important;
      }
    }
  }
}

@media (max-width: 425.98px) {
  .card-insurance {
    padding:  2em 0em;
  }
}
