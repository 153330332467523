.booking-search {
    gap: 5px;
}

.booking-search .buttonSearch {
    width: unset;
}

@media (max-width: 1150px) {
    .booking-search {
        gap: 15px;
        flex-wrap: wrap;
    }

    .booking-search > * {
        flex: 1 0 200px;
        min-width: 180px;
    }

    .booking-search .destination {
        /* flex: 1; */
    }

    .booking-search .checkIn {
        /* flex: 1; */
    }

    .booking-search .checkOut {
        /* flex: 1; */
    }

    .booking-search .guest {
        /* flex: 1; */
    }

    .booking-search .hotelSearch {
        /* flex: 2; */
    }
}