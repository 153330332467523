.main_container {
  height: 100%;
  width: 100%;
  background-color: #f3f3f3;
}

.my-node-enter {
  opacity: 0;
}
.my-node-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}
.my-node-exit {
  opacity: 1;
}
.my-node-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}

.button-enter {
  opacity: 1;
}
.button-enter-active {
  opacity: 0;
  transition: 200ms;
}

.button-exit {
  opacity: 0;
}
.button-exit-active {
  opacity: 1;
  transition: 200ms;
}
.modal-submit-button {
  background-color: #0d6efd !important;
}
