.search-hotel-text {
  color: white;
  font-size: 14px;
  font-weight: bold;
}
.search-hotel-btn {
  width: 650px;
  height: 45px;
  padding: 0 10px;
  display: flex;
  flex-direction: row !important;
  align-items: center;
  background-color: rgb(0, 111, 185);
  border-radius: 5px;
  width: 160px;
  color: white;
}
.filter-input-container {
  background-color: white;
  display: flex;
  flex: 1;
  border-radius: 10px;
  border-bottom-style: solid;
  border-bottom-color: #cdcdcd;
  border-bottom-width: 1px;
  flex-direction: row !important;
  align-items: center;
  padding: 20px;
}
.tab-buttons-container {
  height: 54px;
  align-self: stretch;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.filter-container {
  width: 100%;
  min-height: 150px;
}
.tab-button-container {
  height: 100%;
  align-items: center;
  padding: 0 20px;
  display: flex;
  flex-direction: row !important;
  border-top-left-radius: 8px;
  border-top-right-radius: 10px;
}
.tint-box {
  width: 100%;
  background-color: black;
  opacity: 0.2;
}
.subtitle {
  color: white;
  font-size: 15px;
  display: flex;
  align-self: center;
  font-family: sans-serif;
  letter-spacing: 2px;
  line-height: 1;
  text-align: center;
}
.main-title {
  color: white;
  font-size: 40px;
  display: flex;
  align-self: center;
  font-family: sans-serif;
  letter-spacing: 2px;
  line-height: 1;
  text-align: center;
}
.splash-container {
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

@media (max-width: 575.98px) {
  .main-title {
    line-height: 40px;
  }
  .subtitle{
    line-height: 20px;
  }
}
