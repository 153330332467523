.tabs-ui {
    flex: 1;

    .nav.nav-tabs {
        .nav-item {
            button {
                font-weight: bold;
                font-size: 18px;
                align-self: flex-start;
                color: rgb(26 164 255);

            }

            button.active {
                color: rgb(0, 111, 185);
            }
        }
    }
}