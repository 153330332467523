.sailSchedule-container-table {
  .list_container {
    
    border-bottom-width: 0;
   
  }
  .data-table {
    thead tr {
      background: #006fb9;
      color: white;
      th {
        padding: 0.5rem;
      }
    }
    tbody tr:nth-child(2n + 0) {
      background-color: #d7cfcf;

      color: #646464;
    }
    tbody tr:nth-child(2n + 1) {
      background-color: white;
      color: #646464;
    }
  }
}
