.stepperUI {
    .RFS-StepButton {
        margin: 0 auto;
    }
    
    .RFS-StepButton.active {
        background-color: rgb(237, 29, 36) !important;
    }

    .RFS-StepButton.completed {
        background-color: rgb(161, 3, 8) !important;
    }
}