.guest-room {
    display: block;
    position: absolute;
    bottom: -14em;
    background: #fff;
    padding: 2em 1em 1em;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
}

.guest-room.hidden {
    display: none;
}

.guest-room .guest-list,
.guest-room .room-list{
    display: flex;
    flex-direction: column;
    margin: 0 0 1em;
}

.guest-room .guest-list label,
.guest-room .room-list label {
    background-color: white;
    height: 14px;
    font-size: 12px;
    font-weight: bolder;
    color: rgb(0, 111, 185);
    padding-right: 3px;
    display: flex;
    white-space: nowrap;
    margin-bottom: 5px;
}

.guest-room .guest-list input,
.guest-room .room-list input {
    border-style: solid;
    border-width: 1.5px;
    border-radius: 5px;
    border-color: rgb(199, 199, 199);
}

.guest-room .buttons {
    display: flex;
    gap: 5px;
    justify-content: center;
}

.guest-room .buttons button {
    cursor: pointer;
    display: inline-flex;
    user-select: none;
    flex-direction: row;
    height: 35px;
    color: #fff;
    padding-left: 10px;
    padding-right: 10px;
    align-items: center;
    background-color: rgb(0, 111, 185);
    border-radius: 5px;
    width: 5em;
}

.guest-room .buttons button.submit {
    background-color: rgb(0, 111, 185);
}

.guest-room .buttons button.cancel {
    background-color: rgb(196, 196, 196);
}