.MuiSvgIcon-root{
    font-size: 32px !important;
}
.MuiStepLabel-label{
    font-size: 22px!important;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root {
    font-size: 32px;
}
.css-vnkopk-MuiStepLabel-iconContainer {
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-right: 8px;
    font-size: 32px;
}
Stepper{
    svg{
        font-size: 32px;
    }
}
.da{
    font-size: 32px;
}