textarea:focus,
input:focus {
  outline: none;
}

.Dropdown-root .Dropdown-control {
  height: 100%;
  background-color: #29D54A;
  color: #fff;
  border: none;
  outline: unset;
  display: flex;
  align-items: center;
  padding: 0 2em 0 3.5em;
  position: relative;
  cursor: pointer;
}

.Dropdown-root .Dropdown-control::before {
  content: '';
  position: absolute;
  background-image: url('../../shared/assets/icons/profile.png');
  width: 30px;
  height: 30px;
  left: 10px;
  background-size: contain;
  border-radius: 50%;
  border: solid 2px #fff;
  top: 0;
  bottom: 0;
  margin: auto;
}

.Dropdown-root .Dropdown-control .Dropdown-arrow-wrapper .Dropdown-arrow {
  top: 0;
  bottom: 0;
  margin: auto;
  border-color: #fff transparent transparent;
}

.Dropdown-root.is-open .Dropdown-arrow-wrapper .Dropdown-arrow {
  border-color: transparent transparent #fff;
}

.Dropdown-option {
  text-align: center;
}

.Dropdown-option.settings,
.Dropdown-option.signout {
  position: relative;
}

.Dropdown-option.settings::before {
  content: '';
  position: absolute;
  background-image: url('../../shared/assets/icons/settings.png');
  width: 20px;
  height: 20px;
  left: 15px;
  background-size: contain;
  top: 0;
  bottom: 0;
  margin: auto;
}

.Dropdown-option.signout::before {
  content: '';
  position: absolute;
  background-image: url('../../shared/assets/icons/logout.png');
  width: 20px;
  height: 20px;
  left: 15px;
  background-size: contain;
  top: 0;
  bottom: 0;
  margin: auto;
}
