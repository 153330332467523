.payment-selection {
    width: 100%;
}

.payment-selection h3 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
}

.payment-selection form label {
    font-weight: bold;
}