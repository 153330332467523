.nopadding {
  padding: 0 !important;
  margin: 0 !important;
}
.titleHeader {
  color: #434343;
  font-size: 24px;
  font-weight: 900;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.ferryInformation {
  padding: 0 3rem;
  .form-label,
  option {
    color: #434343;
    font-weight: 900;
  }
  .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3),
  .input-group:not(.has-validation)
    > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    background-color: transparent;
    border-right: none;
  }
  .input-group
    > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    margin-left: -1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none;
  }
  .btn {
    color: #006fb9;
    font-size: 22px;
    font-weight: bolder;
  }
  #add-file {
    background-color: #006fb9;
    color: white;
  }
  .widthFile {
    max-width: 450px;
  }
  .btn-submit {
    background-color: #0bc175;
    color: white;
    padding: 0.8rem 4rem;
    border-radius: 10px;
  }
  .btn-cancel {
    background-color: #e1e1e1;
    color: white;
    padding: 0.8rem 4rem;
    border-radius: 10px;
  }
  .form-control,
  .form-select {
    padding: 0.8rem;
  }
}
.ferry-journey {
  .active {
    border: 5px solid #006fb9;
    border-radius: 24px;
  }
}
.button-submit {
  display: flex;
  justify-content: end;
}
@media (max-width: 768.98px) {
  .ferry-journey {
    .active {
      border: 5px solid #006fb9;
      border-radius: 40px;
    }
  }
}
@media (max-width: 575.98px) {
  .ferry-journey .active {
    border: 5px solid #006fb9;
    border-radius: 24px;
  }
  .widthFile {
    max-width: 150px !important;
  }
  .ferryInformation {
    padding: 0 1rem;
  }
}
