.footer-bottom{
    padding: 20px 0;
    width: 100%;
    background-color: black;
    justify-content: center;
    align-items: center;
    display: flex;
}
.subscribe-btn{
    display: flex;
    justify-content: center;
    height: 50px;
    padding: 0 20px;
    background-color: rgb(58, 58, 58);
    border-radius: 10px;
}
.email-subscription{
    height: 50px;
    align-self: stretch;
    background-color: white;
    border-radius: 10px;
    padding: 0 15px;
}
.footer-content{
    display: flex;
    flex: 1;
    padding: 5px;
    align-items: flex-start;
}
.footer-top{
    width: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: rgb(0, 111, 185);
}
.main-container{
    width: 100%;
    margin-top: 50px;
}
.footer-text-blue{
    font-size: 16px;
    color: white;
}
.footer-text{
    font-size: 16px;
    color: white;
}
.footer-header{
    font-size: 20px;
    color: white;
    font-weight: bold;
}