.option-select {
    -webkit-appearance: none;
    cursor: pointer;
    -moz-appearance: none;
    background: transparent;
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAABHNCSVQICAgIfAhkiAAAAHpJREFUKFPNUskRgCAMJFRiZ0KoC8TOrITrEQeiyDh84Mlmj+wExMSDCa5YhIyIyjnne6tw/I5dACnlEWP01lrNBYwxHgD2EIImg2ZnGuAC1f+ZhRUJPwrjAj1iEXhtuyJcOeqWkzSOXWcCvhyH5DIwan+RI/l751OxE8l2UBBc/FKHAAAAAElFTkSuQmCC');
    background-repeat: no-repeat;
    background-position-x: 95%;
    background-position-y: 50%;
    border: 1px solid #dfdfdf;
    border-radius: 2px;
    padding: 1rem;
    padding-right: 2rem;
    text-transform: capitalize;
}

.upload-file {
    opacity: 0;
    width: 0.1px;
    height: 0.1px;
    position: absolute;
}

.file-input label {
    display: block;
    position: relative;
    width: 100%;
    height: 50px;
    border: 1px solid rgb(217, 217, 217);
    border-radius: 5px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 10px;
    color: #8E8E8E;
    cursor: pointer;
    transition: transform .2s ease-out;
    position: relative;
}

.file-input label::after {
    content: "+ Upload";
    position: absolute;
    height: 100%;
    width: 8%;
    right: 0;
    background-color: #006FB9;
    color: #fff;
    display: flex;
    align-items: center;
    text-align: center;
    margin: 0 auto;
    justify-content: center;
    font-weight: bold;
    border-radius: 0 5px 5px 0;
}

.upload-file:hover + label,
.upload-file:focus + label {
  transform: scale(1.02);
}

.upload-file:focus + label {
    outline: 1px solid #000;
    outline: -webkit-focus-ring-color auto 2px;
}

.select-red {
    color: red !important;
}

.select-red::placeholder {
    color: red !important;
}

@media (max-width: 720px) {
    .file-input label::after {
        width: 90px;
        font-size: 12px;
    }

    .file-input label {
        height: 37px;
    }
}