select::-ms-expand {
  display: none;
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
}
textarea:focus,
input:focus {
  outline: none;
}
select:focus {
  outline: none;
}

input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input[type="checkbox"]:checked {
  background-color: rgba(0, 111, 185, 1);
  color: white;
}

input[type="checkbox"]::-ms-check {
  color: white;
}
