.page_title {
  font-size: 36px;
  font-weight: bold;
  color: rgb(0, 111, 185);
}
.main_container {
  height: 100%;
  width: 100%;
  background-color: #f3f3f3;
}
.custom_btn {
  height: 100px;
  width: 165px;
  background-color: rgb(0, 111, 185);
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  align-self: center;
}
.custom_btn_bottom {
  height: 100px;
  width: 165px;
  background-color: rgb(0, 111, 185);
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.custom_btn_text {
  color: white;
  font-size: 13px;
  font-weight: bold;
}
.sub_title {
  font-size: 22px;
}
h3 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 5;
}
h5 {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5;
}
.custom_btn {
  height: 100px;
  width: 165px;
  background-color: rgb(0, 111, 185);
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  align-self: center;
}
.custom_btn_text {
  color: white;
  font-size: 13px;
  font-weight: bold;
}
.form_input {
  height: 50px;
  flex: 1;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  padding: 8px;
}
.person_num_input {
  height: 50px;
  font-size: 15px;
  flex: 1;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  padding: 8px;
  color: rgb(0, 111, 185);
}
textarea{
    color: #000;
    font-size: 13px;
    border: 1px solid #E1E1E1 ;
    border-radius: 5px;
    height: 150px;
    width: 100%;
    padding-left: 10px;
}
.label{
    color: black;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
}
.li{
    list-style-type:disc ;
    font-size: 14px;
}