.tour-hotel-booking-list {
    cursor: pointer;
    display: inline-flex;
    user-select: none;
    flex-direction: row;
    align-self: stretch;
    border-radius: 10px;
    background-color: white;
    margin-bottom: 20px;
    width: 100%;
    overflow: hidden;
    height: max-content;

    .image {
        height: auto;
        width: 150px;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        min-width: 150px;
        display: block;
        background-size: cover;
        min-height: 100%;

        img {
            height: 100%;
            width: auto;
            object-fit: cover;
        }
    }

    .details {
        width: 100%;

        .header {
            display: flex;
            justify-content: space-between;
            padding: 15px;
            border-bottom: solid 1px rgb(232, 232, 232);
            text-transform: capitalize;

            .title {
                font-weight: bold;
                font-size: 22px;
                color: rgb(0, 111, 185);
                align-self: flex-start;
                margin: 0;
            }

            .asset {
                display: flex;
                gap: 15px;
                align-items: center;

                div {
                    display: flex;
                    gap: 5px;
                    align-items: center;
                }

                .rating {
                    font-size: 14px;
                    color: rgb(252, 194, 3);
                    font-weight: bold;
                    margin: 0;
                    padding: 0;
                }

                .verified {
                    font-size: 14px;
                    color: rgb(0, 111, 185);
                    font-weight: bold;
                    margin: 0;
                    padding: 0;
                }
            }
        }

        .amenities {
            display: inline-flex;
            flex-direction: column;
            flex: 1 1 0%;
            padding: 15px;
            width: 100%;
            gap: 10px;
            text-transform: capitalize;

            .list {
                display: flex;
                align-items: center;

                p {
                    padding: 0;
                    margin: 0;
                    font-size: 14px;
                    color: rgb(100, 100, 100);
                    font-weight: bold;
                }

                img {
                    width: 20px;
                    height: 20px;
                }

                .date,
                .location {
                    display: flex;
                    flex: 1;
                    align-items: center;
                    gap: 5px;
                }
            }

            .places {
                display: flex;
                flex: 1;
                align-items: center;
                gap: 5px;              
            }
        }
    }

    .green {
        display: inline-flex;
        flex-direction: row;
        background-color: rgb(67, 152, 15);
        border-bottom-right-radius: 10px;
        align-items: center;
        align-self: stretch;
        padding: 10px 15px;
        width: 100%;
        justify-content: space-between;

        .price,
        .schedule {
            font-size: 16px;
            color: white;
            font-weight: bold;
        }

        .btn.pay-now-button {
            background-color: #006fb9 !important;
            border: none !important;
            box-shadow: none;
        }
    }

    @media (max-width:700px) {
        height: auto;
        max-width: 500px;
        margin: auto 0;

        .details {
            .header {
                flex-direction: column;
                gap: 5px;
                align-items: flex-start;
            }

            .amenities {
                gap: 5px;

                .list {
                    display: flex;
                    align-items: flex-start;
                    flex-direction: column;
                    gap: 5px;
                }

                .list.places {
                    flex-direction: row;
                }
            }
        }

        .green {
            flex-direction: column;
            align-items: flex-start;
            gap: 5px;
        }

        .image {
            height: unset;
            width: min-content;
            display: block;
            overflow: hidden;

            img {
                height: 100%;
                width: auto;
                object-fit: cover;
            }
        }
    }

    @media (max-width:500px) {
        flex-direction: column;
        margin: 0 0 20px;

        .image {
            width: 100%;
            display: block;
            overflow: hidden;
            border-radius: 0;
        }
    }
}