.star-rating {
    display: flex;
    gap: 1em;

  button {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
  }

  .on {
    color: rgb(252, 194, 3);
  }

  .off {
    color: #ccc;
  }

  span {
    font-size: 30px;
  }
}
