.favorite-property {
  .h5 {
    margin-top: 1rem;
    color: #006fb9;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 0;
  }
  .h5-sub {
    color: #707070;
    font-size: 16px;
    letter-spacing: 0;
    margin-bottom: 0;
  }
  .card {
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: transparent;
    background-clip: border-box;
    border: none;
    border-radius: none;
    .card-text {
      margin-top: 1rem;
      font-size: 14px;
      color: #646464;
    }
    .card-img-overlay .card-img-center {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -100%);
    }
  }
}
.explore {
  .title {
    color: #707070;
    font-size: 22px;
    margin-bottom: 0;
    font-weight: 700;
  }
  .sub-title {
    color: #707070;
    font-size: 18px;
  }
  .card-img-top {
    height: 100%;
    border-top-left-radius: calc(0.25rem - 1px);
    border-bottom-left-radius: calc(0.25rem - 1px);
  }
  .nopadding {
    padding: 0;
  }
  .card-body {
    padding-left: 0;
  }
  .list-group-item {
    height: auto;
    span {
      color: #646464;
      font-weight: 500;
    }
    .title {
      color: #006fb9;
      font-size: 18px;
      font-weight: 700;
    }
  }
  .list-group-item:last-child {
    border: none;
  }
  .list-group {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    background-color: #ffffff;
  }
  .rate{
    color: #FCC203!important;
  }
  .card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: none;
    background-clip: border-box;
    border: none;
    border-radius: 0.25rem;
    background-color: transparent;
  }
  .btn {
    color: #646464 !important;
  }
  .search {
    padding: 2rem;
    background-color: white;
    border-radius: 16px;
    .search-tag {
      color: #006fb9;
      font-size: 24px;
      font-weight: 700;
    }
    .input-group {
      label {
        color: #006fb9;
        left: 1rem;
        position: absolute;
        top: -0.7rem;
        font-size: 14px;
        background-color: white;
        z-index: 1;
      }
      .input-group-text {
        display: flex;
        align-items: center;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #212529;
        text-align: center;
        white-space: nowrap;
        background-color: white;
        border: 1px solid #ced4da;
        border-right: none;
        border-radius: 0.25rem;
        border-bottom-right-radius: 0;
        height: 100%;
      }
      .input-group
        > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
        margin-left: -1px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: none;
      }
    }
    .btn-search {
      background-color: #006fb9;
      width: 100%;
      border-radius: 10px;
    }
  }
}
.Splide {
  p.title {
    color: #707070;
    font-size: 20px;
    margin-bottom: 0.5rem;
    font-weight: 700;
  }
  p.sub-title {
    color: #707070;
    font-size: 16px;
  }
  .title {
    margin-top: 1rem;
    color: #707070;
    font-weight: 700;
    font-size: 18px;
  }
  .subtitle {
    color: #b8b8b8;
    font-size: 16px;
  }
  .splide__pagination li {
    display: none;
  }
  .splide__arrow--next {
    background-color: white;
    color: grey;
    position: absolute;
    right: -20px;
    top: 40%;
    opacity: 1;
    font-size: 22px;
  }
  .splide__arrow--prev {
    background-color: white;
    color: grey;
    position: absolute;
    left: -20px;
    top: 40%;
    opacity: 1;
    font-size: 22px;
  }
}

@media (max-width: 575.98px) {
  .Splide{
    .title {
      margin-top: 1rem;
      color: #707070;
      font-weight: 700;
      font-size: 14px;
    }
    .subtitle {
      color: #b8b8b8;
      font-size: 12px;
    }
  }
  .explore .card-img-top {
    height: 100%;
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px);
    border-bottom-left-radius: 0;
  }
  .card .row {
    padding: 0 1rem;
  }
 
}
