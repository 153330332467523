.main-app {
  box-sizing: border-box;
}

.main-app-content {
  height: 100%;
  background-color: #FFFFFF;
  box-shadow: 0px 3px 6px 0px #00000029;
  -webkit-box-shadow: 0px 3px 6px 0px #00000029;
  -moz-box-shadow: 0px 3px 6px 0px #00000029;
}

.main-app-footer {
  background-color: #171717 !important;
  color: #FFFFFF;
}

html { scroll-behavior: smooth !important; }