.RFS-StepButton-d2,
.RFS-StepButton-d4 {
  background-color: #8e8e8e !important;
}

.your-details {
  .p1 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0;
  }
  .p1-highlight {
    color: #8e8e8e;
    font-size: 16px;
    font-weight: 600;
  }
  .form-label {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0;
    margin-top: 1.5rem;
  }
  input{
      padding: 0.8rem 1rem;
  }
}
