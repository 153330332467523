.sub-text {
  font-size: 17px;
  color: #707070;
  font-weight: bolder;
}
.header-text {
  font-size: 20px;
  color: #707070;
  font-weight: bolder;
}
