.pay-with-maya {
  p {
    text-align: center;
    position: relative;
  }
  p:before {
    content: "";
    display: block;
    width: 550px;
    height: 2px;
    background: #000;
    position: absolute;
    left: 0;
    top: 50%;
    z-index: 0;
  }
  p:after {
    content: "";
    display: block;
    width: 550px;
    height: 2px;
    background: #000;
    position: absolute;
    right: 0;
    top: 50%;
    z-index: 0;
  }
  span {
    position: relative;
    background-color: #f3f3f3;
    padding: 0 5px;
    z-index: 2;
  }
}
.final-steps {
  .p1,
  .MuiFormControlLabel-label {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0;
  }
  .p1-highlight {
    color: #8e8e8e;
    font-size: 16px;
    font-weight: 600;
  }
  .form-label {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0;
    margin-top: 1.5rem;
    padding: 0.8rem;
  }
  .input-group-text {
    background-color: #fff;
    border-right: none;
    padding: 0.8rem;
  }
  .input-group > .form-control,
  .input-group > .form-select {
    border-left: none;
  }
}
.calendar-container {
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 5px;
  align-items: center;
  width: 100%;
  .react-datepicker__input-container {
    align-items: center;
    input {
      padding: 0.8rem;
    }
  }
}
