.inquiries-container {
  padding-top: 4em;
  padding-bottom: 2em;

  .flight-inquiry,
  .ferry-inquiry,
  .insurance-inquiry {
    background-color: #fff;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
    padding: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20em;
    cursor: pointer;

    .image {
      display: flex;
      justify-content: center;
      align-items: center;
      background-size: cover;
      background-position: center;
      background-color: red;
      width: 100%;
      height: 100%;
      position: relative;

      span {
        font-size: 25px;
        font-weight: bolder;
        text-align: center;
        line-height: 1;
        color: #fff;
        text-transform: uppercase;
        position: relative;
        z-index: 99;
      }

      &::before {
        content: '';
        width: 100%;
        height: 100%;
        background: rgba(#222, 0.5);
        position: absolute;
        z-index: 10;
      }
    }
  }

  .flight-inquiry {
    .image {
      background-image: url('../../../shared/assets/images/flight.jpg');
    }
  }

  .ferry-inquiry-2go {
    .image {
      background-image: url('../../../shared/assets/images/2go.png');
    }
  }

  .ferry-inquiry-ocean-jet {
    .image {
      background-image: url('../../../shared/assets/images/ocean jet.png');
    }
  }

  .insurance-inquiry {
    .image {
      background-image: url('../../../shared/assets/images/insurance.jpg');
    }
  }
}
