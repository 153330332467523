.nav.navbar {
  display: flex;
  height: 90px;
  width: 100%;
  background-color: #fff;
  border-bottom-style: solid;
  border-bottom-color: #cdcdcd;
  border-bottom-width: 1px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .navbar-menu {
    display: flex;
    align-items: center;
    height: 100%;

    .nav-link {
      justify-content: center;
      align-items: center;
      height: 100%;
      padding: 0.5rem;

      span {
        font-size: 16px;
        font-weight: bolder;
        text-align: center;
        line-height: 1;
      }
    }

    .nav-dropdown {
      #ferry-dropdown {
        font-size: 16px;
        font-weight: bolder;
        text-align: center;
        line-height: 1;
        color: #000;
        text-transform: uppercase;
        background-color: unset;
        border: none;
        padding: 0.5rem;

        a {
          font-size: 16px;
          font-weight: bolder;
          text-align: center;
          line-height: 1;
          color: #000;
          text-transform: uppercase;
          background-color: unset;
          border: none;
          padding: 0.5rem;
          text-decoration: unset;
        }

        &:focus {
          box-shadow: none;
        }
      }

      .dropdown-menu.show {
        top: -8px !important;
      }

      .dropdown-menu {
        a.dropdown-item {
          font-size: 16px;
          font-weight: bolder;
          text-align: left;
          line-height: 1;
          color: #000;
        }
      }
    }

    .nav-dropdown.active {
      #ferry-dropdown {
        a {
          color: rgb(32, 129, 194);
        }
      }
    }
  }

  .search-menu {
    display: flex;
    align-items: center;
    gap: 25px;

    .divider {
      width: 2px;
      height: 50px;
      background-color: #e5e5e5;
    }
    .traverse-logo {
      img {
        width: 160px;
        height: auto;
      }
    }

    .search-input {
      display: flex;
      height: 25px;
      width: 240px;
      flex-direction: row;
      align-items: center;
      .search_input {
        display: flex;
        flex: 1;
        height: 25px;
        border-style: none;
      }
    }
  }
}

@media (max-width: 1100px) {
  .nav.navbar {
    flex-direction: column;
    height: auto;
    padding: 20px 0 20px;
    gap: 20px;
    flex-wrap: unset;
  }
}

@media (max-width: 767px) {
  .nav.navbar {
    .navbar-menu {
      flex-direction: column;
      gap: 10px;
      width: 100%;
      border-top: solid 1px rgb(205, 205, 205);
      padding-top: 15px;

      .nav-link {
        width: 100%;
      }
    }

    .search-menu {
      gap: 10px;

      .search-input {
        width: 100%;
        flex: 0;

        input {
          &::placeholder {
            font-size: 12px;
          }
        }
      }

      .traverse-logo {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 0;
        width: 100%;

        img {
          width: 114px;
        }
      }
    }
  }
}
