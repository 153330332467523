.maps_bg {
  height: 250px;
  justify-content: center;
  align-items: center;
}

.show_map_btn_text {
  color: white;
  font-size: 14px;
}

.show_map_btn {
  padding: 15px;
  padding-left: 30px;
  padding-right: 30px;
  background-color: rgb(0, 111, 185);
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.pagination_btn_text {
  color: #646464;
  font-size: 14px;
  font-weight: bold;
}

.pagination_btn {
  background-color: white;
  justify-content: center;
  align-items: center;
  padding: 15px;
  align-self: center;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 5px;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
}

.hotel_main_container {
  height: 100%;
  width: 100%;
  background-color: #f3f3f3;

  .main-content {
    display: inline-flex;
    align-self: stretch;
    flex-direction: row;
  }

  @media (max-width: 1000px) {
    .main-content {
      flex-direction: column;
    }
  }
}
