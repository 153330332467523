.sign_in {
  height: 18px;
  width: 18px;
}
.signin-btn {
  height: 45px;
  padding-left: 20px;
  padding-right: 20px;
  flex-direction: row !important;
  align-items: center;
  background-color: #29d54a;
  display: flex;
}
span.header-text {
  color: white;
  font-size: 14px;
  align-self: center;
  font-weight: normal;
}
.account-settings {
  .Dropdown-control {
    min-width: max-content;

    .Dropdown-placeholder {
      // font-size: 13px;
    }
  }
}