.header_text {
  font-size: 18px;
  font-weight: bold;
  color: #434343;
}
.main_container {
  height: 100%;
  width: 100%;
  background-color: header_text;
}
.custom_btn{
    height: 100px;
    width: 165px;
    background-color: rgb(0, 111, 185);
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    align-self: center;
}
.custom_btn_text{
    color: white;
    font-size: 13px;
    font-weight: bold;
}