.centered-label {
  align-items: center;
}

.content {
  padding: 3em 0;

  .title {
    color: #006fb9;
    letter-spacing: 2.5px;
    font-size: 30px;
    font-weight: bolder;
  }

  .buttons-cont {
    
    gap: 1em;
    
    .btn {
     
      background-color: #006fb9;
      color: white;
      padding: 10px 18px;
    
    }
  }

  .search {
    padding: 2rem;
    background-color: white;
    border-radius: 16px;
    .amount {
      color: #006fb9;
      font-weight: 700;
      font-size: 22px;
      margin-bottom: 0;
    }
    .businessClass {
      color: #006fb9;
      font-style: italic;
    }
    .rating {
      color: #3e3e3e;
      font-size: 22px;
      margin-bottom: 0;
    }
    .review{
        color: #8E8E8E;
        font-size: 18px;
    }
    .btn-reserve {
        background-color: #006fb9;
        color: white;
        padding: 10px 30px;
        text-align: center;
        border-radius: 10px;
      }
      .wont-charge{
        color: #8E8E8E;
        font-size: 13px;
        margin: 2rem 0;
      }
      .brown{
        color: #8E8E8E;
      }
    .form-group {
      position: relative;
      .form-label {
        color: #006fb9;
        left: 1rem;
        position: absolute;
        font-weight: 700;
        top: -0.7rem;
        font-size: 14px;
        background-color: white;
        z-index: 1;
      }
    }

    .input-group {
      label {
        color: #006fb9;
        left: 1rem;
        position: absolute;
        top: -0.7rem;
        font-size: 14px;
        background-color: white;
        z-index: 1;
        font-weight: 700;
      }
      .input-group-text {
        display: flex;
        align-items: center;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #212529;
        text-align: center;
        white-space: nowrap;
        background-color: white;
        border: 1px solid #ced4da;
        border-right: none;
        border-radius: 0.25rem;
        border-bottom-right-radius: 0;
        height: 100%;
      }
      .form-control {
        margin-left: -1px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: none;
      }
    }
  }

  .description {
    .description-text {
      color: #434343;
      font-weight: 900;
      font-size: 22px;
      margin: 1rem 0;
    }

    p {
      margin: 0;
    }
  }
  
  p.sailingScheduletext {
    color: #006fb9;
    font-weight: 500;
  }
}

.Splide-oceanjet {
  .splide.splide--slide {
    cursor: pointer;

    .splide__track {
      ul.splide__list {
        li.splide__slide {
          img {
            vertical-align: bottom;
            object-fit: cover;
            height: 100%;
          }
        }
      }
    }
  }

  p.title {
    color: #707070;
    font-size: 20px;
    margin-bottom: 0.5rem;
    font-weight: 700;
  }
  p.sub-title {
    color: #707070;
    font-size: 16px;
  }
  .title {
    margin-top: 1rem;
    color: #707070;
    font-weight: 700;
    font-size: 18px;
  }
  .subtitle {
    color: #b8b8b8;
    font-size: 16px;
  }
  .splide__pagination li {
    display: none;
  }
  .splide__arrow--next {
    background-color: white;
    color: grey;
    position: absolute;
    right: -20px;
    top: 50%;
    opacity: 1;
    font-size: 22px;
  }
  .splide__arrow--prev {
    background-color: white;
    color: grey;
    position: absolute;
    left: -20px;
    top: 50%;
    opacity: 1;
    font-size: 22px;
  }
}

.header-images-title {
  width: max-content;

  .img-cont {
    display: inline-block;
    height: 20em;

    img {
      height: 100%;
      width: 100%;
    }
  }
}