.my-booking {
  .banner {
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('../../../shared/assets/images/banner/hotel_resort.jpg');
    background-position: bottom;
    margin-bottom: 20px;

    .banner-text {
      text-align: center;

      h1 {
        font-size: 26px;
        color: white;
        font-weight: bold;
        text-align: center;
      }
    }
  }

  .main-page {
    padding: 0 96px;

    .sort {
      display: inline-flex;
      flex-direction: row;
      align-self: stretch;
      padding: 15px;
      background-color: white;
      border-radius: 5px;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 30px;

      p {
        padding: 0;
        margin: 0;
        font-size: 14px;
        color: black;
        flex: 1 1 0%;
      }

      .pages {
      }

      .sort-by {
        display: flex;
        gap: 10px;
        align-items: center;

        select {
          width: 200px;
          border-width: 0.5px;
          border-color: rgb(192, 192, 192);
          border-radius: 10px;
          border-style: solid;
          height: 40px;
          padding-left: 10px;
          padding-right: 30px;
          z-index: 10;
          background-color: transparent;
          position: relative;
          -webkit-appearance: none;
          -moz-appearance: none;
          // background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
          background-image: url('../../../shared/assets/images/icon/arrow-down.png');
          background-repeat: no-repeat;
          background-position-x: 94%;
          background-position-y: 50%;
        }
      }
    }

    .item-container {
      max-width: 800px;
      margin: 2em auto 0;

      .loading-scene {
        height: 15em;
        display: flex;
        align-items: center;
        justify-content: center;

        p {
          font-size: 36px;
          font-weight: bold;
          color: rgb(0, 111, 185);
        }

        p.no-booking {
          font-size: 20px;
        }
      }
    }
  }

  .ferry-bookings {
    h2 {
      font-size: 26px;
      font-weight: bold;
      text-align: center;
    }
  }

  @media (max-width: 900px) {
    .main-page {
      padding: 0 12px;

      .item-container {
        max-width: 100%;
        text-align: center;
      }
    }
  }
}

.form-modal.payment-modal {
  .modal-dialog {
    .modal-content {
      .modal-body {
        .attach-file {
          display: none;
        }

        .upload-label {
          background-color: #006fb9;
          color: #fff;
          max-width: 14rem;
          text-align: center;
          cursor: pointer;

          label {
            padding: 1em;
            cursor: pointer;
          }
        }
      }
    }
  }
}
