.RFS-StepButton-d2,
.RFS-StepButton-d4 {
  background-color: #8e8e8e !important;
}

.your-selection {
  .p1 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0;
    color: #5d656d;
  }

  .p1.highlight {
    color: #2c2c2c;
  }

  .p1.bold {
    font-weight: bold;
    color: #000;
    font-size: 20px;
  }

  .promoField {
    max-width: 250px;
    input {
      background-color: #ffffff;
      padding: 15px 10px;
    }
    ::placeholder {
      color: #8e8e8e;
      font-weight: 700;
    }
  }

  .btn-apply {
    margin-top: 1rem;
    border: 1px solid #006fb9;
    color: #006fb9;
    border-radius: 10px;
    font-weight: bolder;
  }

  .p1.align-right {
    text-align: right;
  }
}
